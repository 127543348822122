import {Deserializable} from '../../service/Utils/deserialize';

export class Bug implements Deserializable<Bug> {
  bugId: number;
  projectId: number;
  opportunitiesId: number;
  clientId: number;
  bugTitle: string;
  bugDescription: string;
  bugStatus: string;
  priority: string;
  reporter: number;
  permission: string;
  tenantCompanyKey: string;
  moduleId: number;
  type: string;
  assignedTo: string;
  assignUserData: Participants;
  projectName: string;
  opportunityName: string;
  clientName: string;
  createdTime: string;
  updateTime: string;
  msg: string;
  comments: number;
  attachments: number;
  notes: number;
  task: number;
  activity: number;
  permissionType: number;
  customUsers: any;
  reporterName: any;
  users: any;
  isClientInActive: any;
  companyLogo: string;
  customFieldUpdate: any;
  projectActive: any;
  oppoActive: any;
  isOpporunityActive: number;
  isProjectActive: number;
  onUpdate: string;
  onCreate: string;
  activated: number;
  relatedActivate: number;

  constructor() {
    this.bugId = null;
    this.projectId = null;
    this.opportunitiesId = null;
    this.clientId = null;
    this.bugTitle = null;
    this.bugDescription = null;
    this.bugStatus = null;
    this.priority = null;
    this.reporter = null;
    this.permission = null;
    this.tenantCompanyKey = null;
    this.moduleId = null;
    this.type = null;
    this.assignedTo = null;
    this.assignUserData = null;
    this.projectName = null;
    this.opportunityName = null;
    this.clientName = null;
    this.createdTime = null;
    this.updateTime = null;
    this.comments = null;
    this.attachments = null;
    this.notes = null;
    this.task = null;
    this.activity = null;
    this.permissionType = null;
    this.customUsers = null;
    this.reporterName = null;
    this.users = null;
    this.isClientInActive = null;
    this.companyLogo = null;
    this.customFieldUpdate = [];
    this.projectActive = [];
    this.oppoActive = [];
    this.isOpporunityActive = null;
    this.isProjectActive = null;
    this.onCreate = null;
    this.onUpdate = null;
    this.activated = 1;
    this.relatedActivate = 1;
  }


  deserialize(input: any): Bug {
    Object.assign(this, input);
    return this;
  }

  public getBugId(): number {
    return this.bugId;
  }

  public setBugId(value: number) {
    this.bugId = value;
  }

  public getProjectId(): number {
    return this.projectId;
  }

  public setProjectId(value: number) {
    this.projectId = value;
  }

  public getOpportunitiesId(): number {
    return this.opportunitiesId;
  }

  public setOpportunitiesId(value: number) {
    this.opportunitiesId = value;
  }

  public getClientId(): number {
    return this.clientId;
  }

  public setClientId(value: number) {
    this.clientId = value;
  }

  public getBugTitle(): string {
    return this.bugTitle;
  }

  public setBugTitle(value: string) {
    this.bugTitle = value;
  }

  public getBugDescription(): string {
    return this.bugDescription;
  }

  public setBugDescription(value: string) {
    this.bugDescription = value;
  }

  public getBugStatus(): string {
    return this.bugStatus;
  }

  public setBugStatus(value: string) {
    this.bugStatus = value;
  }

  public getPriority(): string {
    return this.priority;
  }

  public setPriority(value: string) {
    this.priority = value;
  }

  public getReporter(): number {
    return this.reporter;
  }

  public setReporter(value: number) {
    this.reporter = value;
  }

  public getPermission(): string {
    return this.permission;
  }

  public setPermission(value: string) {
    this.permission = value;
  }

  public getTenantCompanyKey(): string {
    return this.tenantCompanyKey;
  }

  public setTenantCompanyKey(value: string) {
    this.tenantCompanyKey = value;
  }

  public getType(): string {
    return this.type;
  }

  public setType(value: string) {
    this.type = value;
  }

  public getModuleId(): number {
    return this.moduleId;
  }

  public setModuleId(value: number) {
    this.moduleId = value;
  }

  public getAssignedTo(): string {
    return this.assignedTo;
  }

  public setAssignedTo(value: string) {
    this.assignedTo = value;
  }

  public getProjectName(): string {
    return this.projectName;
  }

  public setProjectName(value: string) {
    this.projectName = value;
  }

  public getOpportunityName(): string {
    return this.opportunityName;
  }

  public setOpportunityName(value: string) {
    this.opportunityName = value;
  }

  public getClientName(): string {
    return this.clientName;
  }

  public setClientName(value: string) {
    this.clientName = value;
  }
  public getUsers(): string {
    return this.users;
  }

  public setUsers(value: string) {
    this.users = value;
  }






  public getOnCreate(): string {
    return this.createdTime;
  }

  public setOnCreate(value: string) {
    this.createdTime = value;
  }

  public getOnUpdate(): string {
    return this.updateTime;
  }

  public setOnUpdate(value: string) {
    this.updateTime = value;
  }
  public getCompanyLogo(): string {
    return this.companyLogo;
  }

  public setCompanyLogo(value: string) {
    this.companyLogo = value;
  }


}

export interface Participants {
  type: string,
  usersInfo: any
}

export interface IBugData {
  bugId: number;
  moduleId: number;
  type: string;
}
